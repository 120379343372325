/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
    const intl = useIntl()

    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
            />

            <AsideMenuItem
                to='/carts'
                icon='/media/icons/duotune/art/art002.svg'
                title='Trailers'
                fontIcon='bi-app-indicator'
            />

            <AsideMenuItem
                to='/beverages'
                icon='/media/icons/duotune/art/art002.svg'
                title='Beverages'
                fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
                to='/customers'
                icon='/media/icons/duotune/art/art002.svg'
                title='Customers'
                fontIcon='bi-app-indicator'
            />
        </>
    )
}
