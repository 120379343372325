import axios from 'axios';
const URL = `${process.env.REACT_APP_API_URL}/carts`;

function makeJsonForRequest(postData: any){

    return {
        name: postData.name,
        type: postData.type,
        description: postData.description || '',
        address: postData.address || '',
        log: postData.log ? parseFloat(postData.log) : undefined,
        lat: postData.lat ? parseFloat(postData.lat) : undefined,
        tax: postData.tax ? parseFloat(postData.tax) : undefined,
        bar_id: postData.bar_id || null
    };

}
export async function update(postData: any) {
    const response = await axios.put(URL + '/' + postData.id, makeJsonForRequest(postData));
    return response;
}
