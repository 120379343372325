import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DataTable from 'react-data-table-component';
import { RootState } from '../../components/Store';
import { useDispatch, useSelector } from 'react-redux';
import { ICustomer } from '../../interfaces/Customers';
import CreditModal from './Modal/Credit';
import { DCustomer } from '../../defaults/Customers';

const Customer: FC<{ className: string }> = ({ className }) => {
    const [showModal, setShowModal] = useState(false);
    const data = useSelector((state: RootState) => state);
    const [selectedData, setSelectedData] = useState<ICustomer>(DCustomer);
    const [tableData, setTableData] = useState<ICustomer[]>([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleModalClose = () => {
        setShowModal(false);
        setTableData(data.customer.list);
    };

    useEffect(() => {
        setTableData(data.customer.list);
    }, [data.customer.list]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        const filteredData = data.customer.list.filter(customer =>
            customer.name.toLowerCase().includes(query) ||
            customer.email.toLowerCase().includes(query)
        );
        setTableData(filteredData);
    };

    const columns = [
        {
            name: 'Email',
            selector: (row: ICustomer) => row.email,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row: ICustomer) => (
                <div className="d-flex justify-content-end">
                    <button className="btn btn-warning px-5 py-2 mx-2" onClick={() => {
                        setSelectedData(row);
                        setShowModal(true);
                    }}>Add Credit</button>
                </div>
            ),
            ignoreRowClick: true,
            button: true,
            width: '200px',
        },
        {
            name: 'Credit',
            selector: (row: ICustomer) => row.credit,
            sortable: true,
        },
        {
            name: 'Name',
            selector: (row: ICustomer) => row.name,
            sortable: true,
        },
    ];

    return (
        <>
            <CreditModal
                show={showModal}
                handleClose={handleModalClose}
                customer={selectedData}
            />

            <div className={`card ${className}`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">Customers</span>
                    </h3>
                    <div className="card-toolbar">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
                <div className="card-body py-3">
                    <div className="table-responsive">
                        <DataTable
                            columns={columns}
                            data={tableData}
                            keyField="id"
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 20, 30]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export { Customer };
