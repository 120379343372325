import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICustomer } from '../app/interfaces/Customers';

interface CustomerState {
    list: ICustomer[];
}

const initialState: CustomerState = {
    list: [],
};

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setList(state, action: PayloadAction<ICustomer[]>) {
            state.list = action.payload;
        },
        addCustomer(state, action: PayloadAction<ICustomer>) {
            state.list.push(action.payload);
        },
        updateCustomer(state, action: PayloadAction<ICustomer>) {
            const index = state.list.findIndex(customer => customer.id === action.payload.id);
            if (index !== -1) {
                state.list[index] = action.payload;
            }
        },
        deleteCustomer(state, action: PayloadAction<number>) {
            state.list = state.list.filter(customer => customer.id !== action.payload);
        },
        updateCustomerCredit(state, action: PayloadAction<ICustomer>) {
            const index = state.list.findIndex(customer => customer.id === action.payload.id);
            if (index !== -1) {
                state.list[index].credit = Number(action.payload.credit) + Number(state.list[index].credit);
            }
        },
    },
});

export const { setList, addCustomer, updateCustomer, deleteCustomer, updateCustomerCredit } = customerSlice.actions;
export default customerSlice.reducer;
