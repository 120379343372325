import {FC, useEffect} from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { Customer } from './Customer';
import {all as getAllCustomer} from "../../components/requests/Customer";
import {useDispatch} from "react-redux";
import { setList as setCustomerList } from '../../../redux/Customer';

const CustomersWrapper: FC = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        fetchCustomers();
    }, [dispatch]);

    const fetchCustomers = async () => {
        const customersData = await getAllCustomer("");
        dispatch(setCustomerList(customersData));
    };

    return (
        <>
            <PageTitle breadcrumbs={[]}>Customers</PageTitle>
            <div className="col-xl-12">
                <Customer className="card-xxl-stretch mb-5 mb-xl-8" />
            </div>
        </>
    );
};

export { CustomersWrapper };
