import React, { FC, useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { ICreditModalProps } from '../../../interfaces/Customers';
import { DCustomer } from '../../../defaults/Customers';
import { useDispatch } from 'react-redux';
import { updateCustomerCredit } from '../../../../redux/Customer';
import { updateCustomer } from '../../../components/requests/Customer';

const CreditModal: FC<ICreditModalProps> = ({ show, handleClose, customer }) => {
    const [formData, setFormData] = useState(DCustomer);
    const dispatch = useDispatch();

    useEffect(() => {
        setFormData(customer);
    }, [customer]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async () => {
        try {
            const updatedCustomer = await updateCustomer(formData);
            dispatch(updateCustomerCredit(formData));
            handleClose();
        } catch (error) {
            console.error('Error updating customer credit:', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Add Credit - {formData.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formCredit" className="mt-3">
                        <Form.Label>Credit</Form.Label>
                        <Form.Control
                            type="number"
                            name="credit"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Add Credit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreditModal;
