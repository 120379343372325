import axios from 'axios';

export const CUSTOMER_URL = `${process.env.REACT_APP_API_URL}/customers`;

function makeRequestData(postData: any) {
    const formData = new FormData();
    formData.append('name', postData.name);
    formData.append('email', postData.email);
    formData.append('phone', postData.phone);
    formData.append('address', postData.address);
    return formData;
}

export async function all(postData: any) {
    return axios.post(CUSTOMER_URL, postData).then(response => {
        console.log(response.data);
        return response.data;
    }).catch(error => {
        console.error(error);
    });

}

export async function updateCustomer(customer: any) {
    const response = await axios.put(`${CUSTOMER_URL}/addCredit/${customer.id}`, customer);
    return response.data;
}

export function deleteCustomer(postDataId: string) {
    return axios.delete(CUSTOMER_URL + '/' + postDataId, {}).then(response => {
        console.log(response.data);
    }).catch(error => {
        console.error(error);
    });
}
